import {
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Link,
	Picture,
	SquareLogo,
	TroonAccess,
} from '@troon/ui';
import { createSignal } from 'solid-js';
import { AccessProducts } from '../access-products';
import { getConfigValue } from '../../modules/config';
import type { ParentProps } from 'solid-js';

export function UpsellAccess() {
	const [open, setOpen] = createSignal(false);

	return (
		<UpsellAccessSection>
			<Heading as="h2">Join Troon Access. The ultimate way to experience Troon.</Heading>
			<p>Save up to 50% on tee times at 150+ courses with the all-new Troon Access program.</p>
			<div class="flex justify-start gap-4">
				<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
					<DialogTrigger appearance="primary" class="shrink grow-0">
						Join today
					</DialogTrigger>
					<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
						<AccessProducts />
					</DialogContent>
				</Dialog>
				<Button class="w-fit shrink grow-0" appearance="secondary-current" as={Link} href="/access">
					Learn more<span class="sr-only"> about Troon Access</span>
				</Button>
			</div>
		</UpsellAccessSection>
	);
}

export function UpsellAccessSection(props: ParentProps) {
	return (
		<div class="relative grid grid-cols-1 gap-16 overflow-hidden rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-8 text-white md:gap-x-32 md:p-16 lg:grid-cols-2">
			<SquareLogo class="absolute -right-12 -top-16 -z-0 size-64 origin-center rotate-12 text-brand opacity-20 md:size-96" />
			<div class="z-0 flex h-full flex-col justify-center gap-8">
				<TroonAccess class="w-full min-w-36 max-w-64" />
				{props.children}
			</div>
			<Picture
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`}
				alt=""
				sizes={[[640, 480]]}
				class="z-0 hidden size-full overflow-hidden rounded bg-center object-cover lg:block"
				loading="lazy"
			/>
		</div>
	);
}
